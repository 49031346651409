html {
  scroll-behavior: smooth;
}

:root {
  /* Color Variables */
  --svg-color-light: #B133FF;
  --svg-color-dark: #F46737;
  --radius-card: 24px;
  --radius-large: 40px; 
}

/* Light Mode */
body.light {
  --svg-color: var(--svg-color-light);
}

/* Dark Mode */
body.dark {
  --svg-color: var(--accent);
  --dark-gradient: linear-gradient(180deg, var(--background-2) 0%, var(--background-1) 100%);
}

/* SVG Icon Color */
.svg-icon {
  color: var(--svg-color);
}

/* Font Faces */
@font-face {
  font-family: 'Titillium Web Semibold';
  src: url('../public/fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web Regular';
  src: url('../public/fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web Light';
  src: url('../public/fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Additional Styles */
.text-default {
  margin: 0;
}

.about {
  width: fit-content;
}

.content {
  margin-top: 20px;
}

/* Button Styles */
.btn {
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: var(--s-12) var(--s-24);
  border-radius: var(--radius-default);
  font-family: var(--font-family-regular);
  font-size: var(--button-font-size);
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &--filled {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--primary-brand);
    border-radius: var(--radius-round);
    color: var(--background-1);
    padding: var(--s-12) var(--s-24);
    font-size: 18px;

    &:hover {
      background-color: var(--foreground-1);
    }
  }

  &--outlined {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-round);
    border: 1px solid var(--primary-brand);
    background-color: #FFF;
    color: var(--primary-brand);

    &:hover {
      border: 1px solid var(--foreground-1);
      background-color: var(--background-2);
      color: var(--foreground-1);
    }
  }

  &--ghost {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-round);
    background-color: #FFF;
    color: var(--primary-brand);

    &:hover {
      background-color: var(--background-2);
      color: var(--foreground-1);
    }
  }

  &--reversed {
    background-color: var(--background-1);
    color: var(--primary-brand);

    &:hover {
      background-color: var(--background-1);
      color: var(--primary-brand);
    }
  }

  &.label-1 {
    font-family: var(--font-family-regular);
    font-size: var(--button-font-size);
  }
}

/* Dark Mode Button Styles */
body.dark {
  .btn {
    &--filled {
      background-color: var(--primary-brand);

      &:hover {
        background-color: var(--foreground-1);
      }
    }

    &--outlined {
      border: 1px solid var(--primary-brand);
      background-color: var(--background-1);
      color: var(--primary-brand);
      height: 40px;

      &:hover {
        background-color: var(--background-2);
        border: 1px solid var(--foreground-1);
        color: var(--foreground-1);
        height: 40px;
      }
    }

    &--ghost {
      color: var(--primary-brand);
      background-color: var(--background-1);

      &:hover {
        background-color: var(--background-2);
        color: var(--primary-brand);
      }
    }
  }
}
