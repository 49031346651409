.back-to-top {
  position: fixed !important;
  bottom: var(--s-24) !important;
  right: var(--s-24) !important;
  z-index: 1000 !important;
  transition: opacity 0.3s ease, visibility 0.3s ease !important;
}

.back-to-top-button {
  background-color: var(--primary-brand) !important;
  color: var(--background-1) !important;
  border: none !important;
  border-radius: var(--radius-round) !important;
  width: var(--s-40) !important;
  height: var(--s-40) !important;
  font-family: var(--font-family-regular) !important;
  text-align: center !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: background-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease !important;
  opacity: 0.7 !important;

  &:hover {
    background-color: var(--foreground-1) !important;
    opacity: 1 !important;
    transform: scale(1.1) !important;
  }

  &:focus {
    outline: none !important;
  }

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  &::after {
    content: 'Back to top' !important;
    position: absolute !important;
    bottom: calc(100% + 8px) !important;
    right: 50% !important;
    transform: translateX(50%) !important;
    background-color: var(--foreground-1) !important;
    color: var(--background-1) !important;
    padding: 4px 8px !important;
    border-radius: var(--radius-compact) !important;
    font-size: 12px !important;
    white-space: nowrap !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease !important;
    pointer-events: none !important;
    z-index: 1001 !important;
  }

  &.desktop:hover::after {
    opacity: 1 !important;
  }

  &.pulse {
    animation: pulse 2s infinite !important;
  }
}

@media (min-width: 769px) {
  .back-to-top-button {
    display: none !important;
  }
}

/* Pulsing Animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1) !important;
  }
  50% {
    transform: scale(1.05) !important;
  }
}

/* Dark Mode Styles */
body.dark {
  .back-to-top-button {
    background-color: var(--primary-brand) !important;
    color: var(--background-1) !important;

    &.desktop:hover {
      background-color: var(--foreground-1) !important;
    }

    &::after {
      background-color: var(--background-1) !important;
      color: var(--foreground-1) !important;
    }
  }
}
